/* tslint:disable */
/* eslint-disable */

/**
 * Subject of the contact us request
 */
export enum ContactUsCategoryText {
  LoginIssue = 'Login Issue',
  MissingInformationAccessRights = 'Missing Information (access rights)',
  FunctionalityRequest = 'Functionality Request',
  ReportTechnicalProblem = 'Report Technical Problem',
  Feedback = 'Feedback',
  Other = 'Other'
}
