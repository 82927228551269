/* tslint:disable */
/* eslint-disable */

/**
 * The warehouse location.
 */
export enum WarehouseLocation {
  Mumbai = 'Mumbai',
  Chennai = 'Chennai'
}
