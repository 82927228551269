import { CartResponseDto, Currency, WarehouseLocation } from '../../api';

export const mockCart: CartResponseDto = {
  id: '1234',
  orderId: '123123123',
  userInfo: {
    email: 'asf@asdf',
    gstNumber: '123',
    name: 'Helga',
    panNumber: '123',
    phoneNumber: '234',
  },
  shippingAddress: {
    city: 'shipping city',
    street: 'shipping street',
    zipCode: 'shipping zipCode',
    additionalInfo: 'shipping additionalInfo',
  },
  billingAddress: {
    city: 'billing city',
    street: 'billing street',
    zipCode: 'billing zipCode',
    additionalInfo: 'billing additionalInfo',
  },
  poNumber: 'po-1234',
  deliveryInstructions: 'Please leave the delivery with our doorman',
  warehouseLocation: WarehouseLocation.Chennai,
  errors: [
    // 'Sample error message on cart level'
  ],
  warnings: [
    //'Sample warning message on cart level'
  ],
  totalNetPrice: {
    value: 2200.1,
    currency: Currency.Inr,
  },
  totalGrossPrice: {
    value: 2300.2,
    currency: Currency.Inr,
  },
  lineItems: [
    {
      errors: [
        // 'Sample error message on lineItem level',
        // 'The delivery-date is not correct',
        // 'This article is not available anymore since 01.2023',
      ],
      warnings: ['Sample warning message on lineItem level'],
      id: '00000001234',
      product: {
        id: '30067767',
        name: 'Dry Vitamin D2 850',
      },
      article: {
        id: '50059215',
        productId: '30067767',
        name: 'Vit.D2 850 PH 1kg 4G 3',
        packagingSize: {
          value: 100.5,
          unitOfMeasurement: 'kg',
        },
        netPrice: {
          value: 20.1,
          currency: Currency.Inr,
        },
        grossPrice: {
          value: 22.61,
          currency: Currency.Inr,
        },
        grossPricePerUnit: {
          value: 22.61 / 100.5,
          currency: Currency.Inr,
        },
        vat: 12.5,
      },
      quantity: 1,
      netPrice: {
        value: 20.1,
        currency: Currency.Inr,
      },
      grossPrice: {
        value: 22.61,
        currency: Currency.Inr,
      },
      requestedDeliveryDate: '2024-02-15',
    },
    {
      errors: [
        // 'Sample error message on lineItem level',
        // 'The delivery-date is not correct',
        // 'This article is not available anymore since 01.2023',
      ],
      warnings: ['Sample warning message on lineItem level'],
      id: '000000012345',
      product: {
        id: '30067767',
        name: 'Dry Vitamin D2 850',
      },
      article: {
        id: '50059215',
        productId: '30067767',
        name: 'Vit.D2 850 PH 1kg 4G 3',
        packagingSize: {
          value: 100.5,
          unitOfMeasurement: 'kg',
        },
        netPrice: {
          value: 20.1,
          currency: Currency.Inr,
        },
        grossPrice: {
          value: 22.61,
          currency: Currency.Inr,
        },
        grossPricePerUnit: {
          value: 22.61 / 100.5,
          currency: Currency.Inr,
        },
        vat: 12.5,
      },
      quantity: 2,
      netPrice: {
        value: 40.2,
        currency: Currency.Inr,
      },
      grossPrice: {
        value: 45.22,
        currency: Currency.Inr,
      },
      requestedDeliveryDate: '2024-02-15',
    },
  ],
  termsAndConditionsAccepted: true,
};

export const emptyMockCart: CartResponseDto = {
  id: '682da325-007e-4848-a6b7-63d0fa9157e3',
  orderId: '2f96cf94-cd72-4d51-97a1-bbbefdca255f',
  lineItems: [],
  userInfo: {
    name: 'Joshua Arens',
    email: 'mwcustomerevp@gmail.com',
    phoneNumber: '',
    gstNumber: '',
    panNumber: '',
  },
  shippingAddress: {
    city: '',
    street: '',
    zipCode: '',
    additionalInfo: '',
  },
  billingAddress: {
    city: '',
    street: '',
    zipCode: '',
    additionalInfo: '',
  },
  poNumber: null,
  deliveryInstructions: null,
  warehouseLocation: null,
  totalNetPrice: {
    value: 0,
    currency: Currency.Inr,
  },
  totalGrossPrice: {
    value: 0,
    currency: Currency.Inr,
  },
  termsAndConditionsAccepted: false,
  warnings: [],
  errors: [],
};
