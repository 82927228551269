import {
  Currency,
  NavigatorFilterIdEnum,
  ProductGroupFilterIdEnum,
  ProductListDto,
  SimpleFilterIdEnum,
  SimpleProductDto,
  SimpleProductsResponseDto,
} from '../../api';

export const mockProduct = {
  id: '123',
  name: 'MOCK Chimassorb 2020 FDL ',
  slug: 'chimassorb-2020-fdl',
  minPrice: null,
  alphaCode: 'ABC',
  productGroup: 'AB',
  sbu: 'EVG',
  description:
    'This is description for Chimassorb 2020 FDL, internal material number is [30486994]',
};

export const mockProductList: ProductListDto = {
  products: [
    {
      minPrice: {
        value: 10.1,
        currency: Currency.Inr,
      },
      id: '123',
      name: 'MOCK Chimassorb 2020 FDL ',
      slug: 'chimassorb-2020-fdl',
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Chimassorb 2020 FDL, internal material number is [30486994]',
    },
    {
      id: '30501947',
      name: 'Uvinul 3030 FF',
      slug: 'uvinul-3030-ff',
      description: 'This is description for Uvinul 3030 FF, internal material number is [30501947]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVO',
    },
    {
      id: '30141931',
      name: 'Irganox 1035',
      slug: 'irganox-1035',
      description: 'This is description for Irganox 1035, internal material number is [30141931]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVJ',
    },
    {
      id: '30475161',
      name: 'Irganox 1330',
      slug: 'irganox-1330',
      description: 'This is description for Irganox 1330, internal material number is [30475161]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVO',
    },
    {
      id: '30482345',
      name: 'Irgastat P 16',
      slug: 'irgastat-p-16',
      description: 'This is description for Irgastat P 16, internal material number is [30482345]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVA',
    },
    {
      id: '30070416',
      name: 'Irganox B 1171',
      slug: 'irganox-b-1171',
      description: 'This is description for Irganox B 1171, internal material number is [30070416]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVJ',
    },
    {
      id: '30473476',
      name: 'Irganox B 900',
      slug: 'irganox-b-900',
      description: 'This is description for Irganox B 900, internal material number is [30473476]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30498225',
      name: 'Tinuvin XT 847 FF',
      slug: 'tinuvin-xt-847-ff',
      description:
        'This is description for Tinuvin XT 847 FF, internal material number is [30498225]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30481121',
      name: 'Irganox 565 DD',
      slug: 'irganox-565-dd',
      description: 'This is description for Irganox 565 DD, internal material number is [30481121]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30487023',
      name: 'Tinuvin 111 FDL',
      slug: 'tinuvin-111-fdl',
      description:
        'This is description for Tinuvin 111 FDL, internal material number is [30487023]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30481487',
      name: 'Melapur MC50',
      slug: 'melapur-mc50',
      description: 'This is description for Melapur MC50, internal material number is [30481487]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30475154',
      name: 'Irganox 565',
      slug: 'irganox-565',
      description: 'This is description for Irganox 565, internal material number is [30475154]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30137471',
      name: 'Tinuvin 571',
      slug: 'tinuvin-571',
      description: 'This is description for Tinuvin 571, internal material number is [30137471]',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
    },
    {
      id: '30666496',
      name: 'Irgastab PUR 70',
      slug: 'irgastab-pur-70',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab PUR 70, internal material number is [30666496]',
    },
    {
      id: '30478607',
      name: 'Tinuvin 1577 ED',
      slug: 'tinuvin-1577-ed',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin 1577 ED, internal material number is [30478607]',
    },
    {
      id: '30066298',
      name: 'Chimassorb 944 LD',
      slug: 'chimassorb-944-ld',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Chimassorb 944 LD, internal material number is [30066298]',
    },
    {
      id: '30482901',
      name: 'Tinuvin 234 FF',
      slug: 'tinuvin-234-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 234 FF, internal material number is [30482901]',
    },
    {
      id: '30106585',
      name: 'Irganox 245 FF',
      slug: 'irganox-245-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox 245 FF, internal material number is [30106585]',
    },
    {
      id: '30480832',
      name: 'Irganox 1076 Melt',
      slug: 'irganox-1076-melt',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox 1076 Melt, internal material number is [30480832]',
    },
    {
      id: '30472148',
      name: 'Tinuvin® 783 FDL',
      slug: 'tinuvin-783-fdl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin® 783 FDL, internal material number is [30472148]',
    },
    {
      id: '30483479',
      name: 'Irgastab FS 811 FF',
      slug: 'irgastab-fs-811-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab FS 811 FF, internal material number is [30483479]',
    },
    {
      id: '30065709',
      name: 'Irganox 245',
      slug: 'irganox-245',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox 245, internal material number is [30065709]',
    },
    {
      id: '30477067',
      name: 'Tinuvin 360',
      slug: 'tinuvin-360',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 360, internal material number is [30477067]',
    },
    {
      id: '30097245',
      name: 'Irganox 1098',
      slug: 'irganox-1098',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox 1098, internal material number is [30097245]',
    },
    {
      id: '30482677',
      name: 'Tinuvin XT 850 FF',
      slug: 'tinuvin-xt-850-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin XT 850 FF, internal material number is [30482677]',
    },
    {
      id: '30484363',
      name: 'Irgafos® 168 FF',
      slug: 'irgafos-168-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgafos® 168 FF, internal material number is [30484363]',
    },
    {
      id: '30261630',
      name: 'Tinuvin® 329',
      slug: 'tinuvin-329',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin® 329, internal material number is [30261630]',
    },
    {
      id: '30482427',
      name: 'Irgastab FS 410 FF',
      slug: 'irgastab-fs-410-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab FS 410 FF, internal material number is [30482427]',
    },
    {
      id: '30208464',
      name: 'Chimassorb 944 FDL',
      slug: 'chimassorb-944-fdl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Chimassorb 944 FDL, internal material number is [30208464]',
    },
    {
      id: '30292176',
      name: 'Irganox PS 800 FL',
      slug: 'irganox-ps-800-fl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox PS 800 FL, internal material number is [30292176]',
    },
    {
      id: '30486961',
      name: 'Irgastab NA 287',
      slug: 'irgastab-na-287',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab NA 287, internal material number is [30486961]',
    },
    {
      id: '30474369',
      name: 'Irganox 1035 FF (W&C)',
      slug: 'irganox-1035-ff-wc',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox 1035 FF (W&C), internal material number is [30474369]',
    },
    {
      id: '30254367',
      name: 'Irgafos 126',
      slug: 'irgafos-126',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irgafos 126, internal material number is [30254367]',
    },
    {
      id: '30551425',
      name: 'Tinuvin 1600',
      slug: 'tinuvin-1600',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 1600, internal material number is [30551425]',
    },
    {
      id: '30119547',
      name: 'Irganox® 1076',
      slug: 'irganox-1076',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox® 1076, internal material number is [30119547]',
    },
    {
      id: '30480989',
      name: 'Irganox E 201',
      slug: 'irganox-e-201',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox E 201, internal material number is [30480989]',
    },
    {
      id: '30483293',
      name: 'Tinuvin 329 FF',
      slug: 'tinuvin-329-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 329 FF, internal material number is [30483293]',
    },
    {
      id: '30475047',
      name: 'Melapur MC XL',
      slug: 'melapur-mc-xl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Melapur MC XL, internal material number is [30475047]',
    },
    {
      id: '30482001',
      name: 'Irgastat P 20',
      slug: 'irgastat-p-20',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irgastat P 20, internal material number is [30482001]',
    },
    {
      id: '30474464',
      name: 'Tinuvin NOR 371 FF',
      slug: 'tinuvin-nor-371-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin NOR 371 FF, internal material number is [30474464]',
    },
    {
      id: '30476168',
      name: 'Irgastab PUR 67',
      slug: 'irgastab-pur-67',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab PUR 67, internal material number is [30476168]',
    },
    {
      id: '30472828',
      name: 'Tinuvin 326',
      slug: 'tinuvin-326',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 326, internal material number is [30472828]',
    },
    {
      id: '30485581',
      name: 'Melapur MC25',
      slug: 'melapur-mc25',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Melapur MC25, internal material number is [30485581]',
    },
    {
      id: '30472106',
      name: 'Flamestab NOR 116 FF',
      slug: 'flamestab-nor-116-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Flamestab NOR 116 FF, internal material number is [30472106]',
    },
    {
      id: '30069450',
      name: 'Uvinul 3030',
      slug: 'uvinul-3030',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Uvinul 3030, internal material number is [30069450]',
    },
    {
      id: '30485060',
      name: 'Tinopal OB',
      slug: 'tinopal-ob',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinopal OB, internal material number is [30485060]',
    },
    {
      id: '30478606',
      name: 'Tinuvin 360 ED',
      slug: 'tinuvin-360-ed',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 360 ED, internal material number is [30478606]',
    },
    {
      id: '30481126',
      name: 'Tinuvin 494 AR',
      slug: 'tinuvin-494-ar',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 494 AR, internal material number is [30481126]',
    },
    {
      id: '30503662',
      name: 'Tinuvin PA 123',
      slug: 'tinuvin-pa-123',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin PA 123, internal material number is [30503662]',
    },
    {
      id: '30490071',
      name: 'Irgaclear XT 386',
      slug: 'irgaclear-xt-386',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgaclear XT 386, internal material number is [30490071]',
    },
    {
      id: '30480676',
      name: 'Tinuvin 326 FL',
      slug: 'tinuvin-326-fl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 326 FL, internal material number is [30480676]',
    },
    {
      id: '30736869',
      name: 'Tinuvin NOR 356 FF',
      slug: 'tinuvin-nor-356-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin NOR 356 FF, internal material number is [30736869]',
    },
    {
      id: '30648494',
      name: 'Tinuvin XT 835 FF',
      slug: 'tinuvin-xt-835-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin XT 835 FF, internal material number is [30648494]',
    },
    {
      id: '30050510',
      name: 'Tinuvin 770 DF',
      slug: 'tinuvin-770-df',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 770 DF, internal material number is [30050510]',
    },
    {
      id: '30413938',
      name: 'Chimassorb 81 FL',
      slug: 'chimassorb-81-fl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Chimassorb 81 FL, internal material number is [30413938]',
    },
    {
      id: '30492867',
      name: 'Irganox 1425',
      slug: 'irganox-1425',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox 1425, internal material number is [30492867]',
    },
    {
      id: '30472068',
      name: 'Irganox 3114',
      slug: 'irganox-3114',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox 3114, internal material number is [30472068]',
    },
    {
      id: '30475046',
      name: 'Irganox B 215 FF',
      slug: 'irganox-b-215-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox B 215 FF, internal material number is [30475046]',
    },
    {
      id: '30484384',
      name: 'Melapur MC15',
      slug: 'melapur-mc15',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Melapur MC15, internal material number is [30484384]',
    },
    {
      id: '30476352',
      name: 'Irgastat P 22',
      slug: 'irgastat-p-22',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irgastat P 22, internal material number is [30476352]',
    },
    {
      id: '30436459',
      name: 'Irganox 1425 WL',
      slug: 'irganox-1425-wl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox 1425 WL, internal material number is [30436459]',
    },
    {
      id: '30475225',
      name: 'Irganox MD 1024',
      slug: 'irganox-md-1024',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox MD 1024, internal material number is [30475225]',
    },
    {
      id: '30051837',
      name: 'Uvinul 3035',
      slug: 'uvinul-3035',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Uvinul 3035, internal material number is [30051837]',
    },
    {
      id: '30215496',
      name: 'Tinuvin 312',
      slug: 'tinuvin-312',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 312, internal material number is [30215496]',
    },
    {
      id: '30765224',
      name: 'Tinuvin NOR 371 AR',
      slug: 'tinuvin-nor-371-ar',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin NOR 371 AR, internal material number is [30765224]',
    },
    {
      id: '30473478',
      name: 'Irganox B 215',
      slug: 'irganox-b-215',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox B 215, internal material number is [30473478]',
    },
    {
      id: '30498901',
      name: 'Tinuvin B 75',
      slug: 'tinuvin-b-75',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin B 75, internal material number is [30498901]',
    },
    {
      id: '30056309',
      name: 'Uvinul 3039',
      slug: 'uvinul-3039',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Uvinul 3039, internal material number is [30056309]',
    },
    {
      id: '30065622',
      name: 'Tinuvin P',
      slug: 'tinuvin-p',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin P, internal material number is [30065622]',
    },
    {
      id: '30479291',
      name: 'Irgastab FS 533 FF',
      slug: 'irgastab-fs-533-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab FS 533 FF, internal material number is [30479291]',
    },
    {
      id: '30474952',
      name: 'Tinuvin 120',
      slug: 'tinuvin-120',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 120, internal material number is [30474952]',
    },
    {
      id: '30215814',
      name: 'Irganox® 1010 FF',
      slug: 'irganox-1010-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox® 1010 FF, internal material number is [30215814]',
    },
    {
      id: '30472796',
      name: 'Chimassorb® 81',
      slug: 'chimassorb-81',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Chimassorb® 81, internal material number is [30472796]',
    },
    {
      id: '30733375',
      name: 'Irganox B 561 ED',
      slug: 'irganox-b-561-ed',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox B 561 ED, internal material number is [30733375]',
    },
    {
      id: '30508206',
      name: 'Tinuvin XT 200 FF',
      slug: 'tinuvin-xt-200-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin XT 200 FF, internal material number is [30508206]',
    },
    {
      id: '30472129',
      name: 'Melapur 200 70',
      slug: 'melapur-200-70',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Melapur 200 70, internal material number is [30472129]',
    },
    {
      id: '30178671',
      name: 'Irganox 1098 ED',
      slug: 'irganox-1098-ed',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox 1098 ED, internal material number is [30178671]',
    },
    {
      id: '30505673',
      name: 'Irgasurf SR 100 B',
      slug: 'irgasurf-sr-100-b',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgasurf SR 100 B, internal material number is [30505673]',
    },
    {
      id: '30229687',
      name: 'Irganox B 225 FF',
      slug: 'irganox-b-225-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox B 225 FF, internal material number is [30229687]',
    },
    {
      id: '30479355',
      name: 'Tinuvin PUR 866',
      slug: 'tinuvin-pur-866',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin PUR 866, internal material number is [30479355]',
    },
    {
      id: '30480598',
      name: 'Tinuvin® 329 FL',
      slug: 'tinuvin-329-fl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin® 329 FL, internal material number is [30480598]',
    },
    {
      id: '30641901',
      name: 'Tinuvin XT 55 FB',
      slug: 'tinuvin-xt-55-fb',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin XT 55 FB, internal material number is [30641901]',
    },
    {
      id: '30497213',
      name: 'Irgafos 168',
      slug: 'irgafos-168',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irgafos 168, internal material number is [30497213]',
    },
    {
      id: '30629676',
      name: 'Irgatec CR 76 IC',
      slug: 'irgatec-cr-76-ic',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgatec CR 76 IC, internal material number is [30629676]',
    },
    {
      id: '30483175',
      name: 'Melapur 200',
      slug: 'melapur-200',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Melapur 200, internal material number is [30483175]',
    },
    {
      id: '30048150',
      name: 'Irganox 1010 ***Test',
      slug: 'irganox-1010',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox 1010, internal material number is [30048150]',
    },
    {
      id: '30506847',
      name: 'Irgastat P 18 FCA',
      slug: 'irgastat-p-18-fca',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastat P 18 FCA, internal material number is [30506847]',
    },
    {
      id: '30420888',
      name: 'Irgastab FS 301 FF',
      slug: 'irgastab-fs-301-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab FS 301 FF, internal material number is [30420888]',
    },
    {
      id: '30476393',
      name: 'Tinuvin XT 855 FF',
      slug: 'tinuvin-xt-855-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Tinuvin XT 855 FF, internal material number is [30476393]',
    },
    {
      id: '30229846',
      name: 'Irganox PS 802 FL',
      slug: 'irganox-ps-802-fl',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox PS 802 FL, internal material number is [30229846]',
    },
    {
      id: '30054540',
      name: 'Irganox B 225',
      slug: 'irganox-b-225',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Irganox B 225, internal material number is [30054540]',
    },
    {
      id: '30503488',
      name: 'Tinuvin 622 SF',
      slug: 'tinuvin-622-sf',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 622 SF, internal material number is [30503488]',
    },
    {
      id: '30051845',
      name: 'Uvinul 5050 H',
      slug: 'uvinul-5050-h',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Uvinul 5050 H, internal material number is [30051845]',
    },
    {
      id: '30501273',
      name: 'Uvinul 4050 FF',
      slug: 'uvinul-4050-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Uvinul 4050 FF, internal material number is [30501273]',
    },
    {
      id: '30097325',
      name: 'Tinuvin 234',
      slug: 'tinuvin-234',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 234, internal material number is [30097325]',
    },
    {
      id: '30140269',
      name: 'Irganox® 1076 FD',
      slug: 'irganox-1076-fd',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irganox® 1076 FD, internal material number is [30140269]',
    },
    {
      id: '30097326',
      name: 'Tinuvin 791 FB',
      slug: 'tinuvin-791-fb',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description: 'This is description for Tinuvin 791 FB, internal material number is [30097326]',
    },
    {
      id: '30482304',
      name: 'Irgastab FS 210 FF',
      slug: 'irgastab-fs-210-ff',
      minPrice: null,
      alphaCode: 'ABC',
      productGroup: 'AB',
      sbu: 'EVG',
      description:
        'This is description for Irgastab FS 210 FF, internal material number is [30482304]',
    },
  ],
  filters: [
    {
      id: SimpleFilterIdEnum.SearchFilter,
    },
    {
      id: NavigatorFilterIdEnum.IndustryFilter,
    },
    {
      id: ProductGroupFilterIdEnum.FunctionsFilter,
      options: [
        {
          id: '/8a8082cf84f03e4d01850a3aeec718f7/8a8082cf84f03e4d01850a3b9eae18f9',
          title: 'Acid Scavenger',
        },
        {
          id: '/8a8082cf84f03e4d01850a3aeec718f7/8a8082d486019a2901860c4bb4b81310',
          title: 'Anti Gas Migration',
        },
      ],
    },
    {
      id: ProductGroupFilterIdEnum.PolymersFilter,
      options: [
        {
          id: '/8a8082cf84f03e4d01850a44b55c1b40/8a8082cf84f03e4d01850a452a5a1b42',
          title: 'Polyamide (PA)',
        },
        {
          id: '/8a8082cf84f03e4d01850a44b55c1b40/8a8082cf84f03e4d01850a4acda51bad',
          title: 'Polycarbonate (PC)',
        },
      ],
    },
  ],
};

export const mockSimpleProductList: SimpleProductsResponseDto = {
  products: mockProductList.products.map((p: SimpleProductDto) => ({
    id: p.id,
    name: p.name,
    sbu: p.sbu,
    alphaCode: p.alphaCode,
    productGroup: p.productGroup,
  })),
};
