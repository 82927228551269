/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContactUsInquiryRequestBodyDto } from '../models/contact-us-inquiry-request-body-dto';
import { ContactUsInquiryResponseDto } from '../models/contact-us-inquiry-response-dto';
import { DocumentInquiryRequestBodyDto } from '../models/document-inquiry-request-body-dto';
import { DocumentInquiryResponseDto } from '../models/document-inquiry-response-dto';
import { SupportInquiryCategory } from '../models/support-inquiry-category';
import { SupportInquiryRequestBodyDto } from '../models/support-inquiry-request-body-dto';
import { SupportInquiryResponseDto } from '../models/support-inquiry-response-dto';

@Injectable({
  providedIn: 'root',
})
export class RequestSupportApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentAccessRequest
   */
  static readonly DocumentAccessRequestPath = '/api/requests/document-access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentAccessRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentAccessRequest$Response(params: {
    body: DocumentInquiryRequestBodyDto
  }): Observable<StrictHttpResponse<DocumentInquiryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.DocumentAccessRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentAccessRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentAccessRequest(params: {
    body: DocumentInquiryRequestBodyDto
  }): Observable<DocumentInquiryResponseDto> {

    return this.documentAccessRequest$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentInquiryResponseDto>) => r.body as DocumentInquiryResponseDto)
    );
  }

  /**
   * Path part for operation contactUsForm
   */
  static readonly ContactUsFormPath = '/api/requests/contact-us';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactUsForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactUsForm$Response(params: {
    body: ContactUsInquiryRequestBodyDto
  }): Observable<StrictHttpResponse<ContactUsInquiryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.ContactUsFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactUsInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactUsForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactUsForm(params: {
    body: ContactUsInquiryRequestBodyDto
  }): Observable<ContactUsInquiryResponseDto> {

    return this.contactUsForm$Response(params).pipe(
      map((r: StrictHttpResponse<ContactUsInquiryResponseDto>) => r.body as ContactUsInquiryResponseDto)
    );
  }

  /**
   * Path part for operation supportInquiryRequest
   */
  static readonly SupportInquiryRequestPath = '/api/requests/{inquiryCategory}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportInquiryRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportInquiryRequest$Response(params: {
    inquiryCategory: SupportInquiryCategory;
    body: SupportInquiryRequestBodyDto
  }): Observable<StrictHttpResponse<SupportInquiryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.SupportInquiryRequestPath, 'post');
    if (params) {
      rb.path('inquiryCategory', params.inquiryCategory, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `supportInquiryRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportInquiryRequest(params: {
    inquiryCategory: SupportInquiryCategory;
    body: SupportInquiryRequestBodyDto
  }): Observable<SupportInquiryResponseDto> {

    return this.supportInquiryRequest$Response(params).pipe(
      map((r: StrictHttpResponse<SupportInquiryResponseDto>) => r.body as SupportInquiryResponseDto)
    );
  }

}
