/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  AdminPanelOrdersResponseDto,
  CartPatchRequestBodyDto,
  CartResponseDto,
  PurchaseApiService,
} from '../../api';
import {
  emptyMockCart,
  mockCart,
  mockCategoryOptions,
  mockPlacedOrdersResponse,
  mockProductDetails,
} from '../data';

const localDB = {
  productDetails: mockProductDetails,
  categoryOptions: mockCategoryOptions,
  placedOrdersResponse: mockPlacedOrdersResponse,
};

const serverDelay = 500; // ms

export const mockPurchaseApiService: Partial<PurchaseApiService> = {
  getCurrentCart: function (params?: { locale?: string }): Observable<CartResponseDto> {
    return of(emptyMockCart).pipe(delay(serverDelay));
  },
  patchCurrentCart(params: {
    locale?: string;
    body: CartPatchRequestBodyDto;
  }): Observable<CartResponseDto> {
    return of(mockCart).pipe(delay(serverDelay));
  },
  getAllPlacedOrders(params?: any): Observable<AdminPanelOrdersResponseDto> {
    return of(localDB.placedOrdersResponse).pipe(delay(serverDelay));
  },
};
