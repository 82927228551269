/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  ContactUsInquiryRequestBodyDto,
  ContactUsInquiryResponseDto,
  DocumentInquiryRequestBodyDto,
  DocumentInquiryResponseDto,
  RequestSupportApiService,
  SupportInquiryRequestBodyDto,
  SupportInquiryResponseDto,
} from '../../api';

const localDB = {};

const serverDelay = 500; // ms

export const mockRequestSupportApiService: Partial<RequestSupportApiService> = {
  documentAccessRequest(params: {
    body: DocumentInquiryRequestBodyDto;
  }): Observable<DocumentInquiryResponseDto> {
    return of();
  },

  supportInquiryRequest(params: {
    body: SupportInquiryRequestBodyDto;
  }): Observable<SupportInquiryResponseDto> {
    console.log(`[MockRequestApiService::commercialRequest] body: `, params.body);
    return of();
  },

  contactUsForm(params: {
    body: ContactUsInquiryRequestBodyDto;
  }): Observable<ContactUsInquiryResponseDto> {
    console.log(`[SERVER REQUEST] /contact-us`, { params });
    return of().pipe(delay(serverDelay));
  },
};
