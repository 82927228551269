/* tslint:disable */
/* eslint-disable */

/**
 * Status of the article, default on DB level is "INACTIVE"
 */
export enum ArticleStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}
