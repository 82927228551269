/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import { AuthenticationApiService, AuthLoginRequestBodyDto, UserBasicInfoDto } from '../../api';
import { mockUserInfo } from '../data';

const localDB = {};

const serverDelay = 500; // ms

export const mockAuthenticationApiService: Partial<AuthenticationApiService> = {
  login(params: { body: AuthLoginRequestBodyDto }): Observable<UserBasicInfoDto> {
    console.log('[AuthenticationApiService::login]');
    return of(mockUserInfo).pipe(delay(serverDelay));
  },

  logout(params?: unknown): Observable<void> {
    console.log('[AuthenticationApiService::logout]');
    return of(undefined).pipe(delay(serverDelay));
  },
};
