/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthenticationApiService } from './services/authentication-api.service';
import { DocumentsApiService } from './services/documents-api.service';
import { RequestSupportApiService } from './services/request-support-api.service';
import { ProductsApiService } from './services/products-api.service';
import { UserInformationApiService } from './services/user-information-api.service';
import { PurchaseApiService } from './services/purchase-api.service';
import { VersionApiService } from './services/version-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthenticationApiService,
    DocumentsApiService,
    RequestSupportApiService,
    ProductsApiService,
    UserInformationApiService,
    PurchaseApiService,
    VersionApiService,
    ApiConfiguration
  ],
})
export class GeneratedApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<GeneratedApiModule> {
    return {
      ngModule: GeneratedApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: GeneratedApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('GeneratedApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
