import { Provider } from '@nestjs/common';

import {
  AuthenticationApiService,
  ProductsApiService,
  PurchaseApiService,
  RequestSupportApiService,
  UserInformationApiService,
} from './api';
import { ApiConfiguration } from './api/api-configuration';
import {
  mockAuthenticationApiService,
  mockProductsApiService,
  mockUserInfoApiService,
} from './mock';
import { mockPurchaseApiService } from './mock/services/mock-purchase-api.service';
import { mockRequestSupportApiService } from './mock/services/mock-request-support-api.service';

export function getDefaultApiProviders(rootUrl = ''): Provider[] {
  return [
    {
      provide: ApiConfiguration,
      useValue: { rootUrl },
    },
  ];
}

export function getMockApiProviders(): Provider[] {
  return [
    {
      provide: AuthenticationApiService,
      useValue: mockAuthenticationApiService,
    },
    {
      provide: UserInformationApiService,
      useValue: mockUserInfoApiService,
    },
    {
      provide: ProductsApiService,
      useValue: mockProductsApiService,
    },
    {
      provide: RequestSupportApiService,
      useValue: mockRequestSupportApiService,
    },
    {
      provide: PurchaseApiService,
      useValue: mockPurchaseApiService,
    },
    // {
    //   provide: VersionApiService,
    //   useValue: mockVersionApiService,
    // },
  ];
}

export function getApiServiceProviders(apiSettings: ApiSettings): Provider[] {
  const { apiBaseUrl, useMockData } = apiSettings;

  let apiSetupProvider = getDefaultApiProviders(apiBaseUrl);

  if (useMockData) {
    apiSetupProvider = getMockApiProviders();
  }

  return apiSetupProvider;
}

interface ApiSettings {
  apiBaseUrl?: string;
  useMockData?: boolean;
}
