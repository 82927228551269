/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPanelOrdersResponseDto } from '../models/admin-panel-orders-response-dto';
import { CartPatchRequestBodyDto } from '../models/cart-patch-request-body-dto';
import { CartResponseDto } from '../models/cart-response-dto';
import { OrderCartDto } from '../models/order-cart-dto';
import { OrderPlacedResponseDto } from '../models/order-placed-response-dto';
import { PaymentLinkResponseDto } from '../models/payment-link-response-dto';
import { VerifyPaymentResponseDto } from '../models/verify-payment-response-dto';

@Injectable({
  providedIn: 'root',
})
export class PurchaseApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCurrentCart
   */
  static readonly GetCurrentCartPath = '/api/purchase/cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCart$Response(params?: {
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.GetCurrentCartPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCart(params?: {
  }): Observable<CartResponseDto> {

    return this.getCurrentCart$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation deleteCurrentCart
   */
  static readonly DeleteCurrentCartPath = '/api/purchase/cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCurrentCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCurrentCart$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.DeleteCurrentCartPath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCurrentCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCurrentCart(params?: {
  }): Observable<void> {

    return this.deleteCurrentCart$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchCurrentCart
   */
  static readonly PatchCurrentCartPath = '/api/purchase/cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCurrentCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCurrentCart$Response(params: {
    body: CartPatchRequestBodyDto
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.PatchCurrentCartPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchCurrentCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCurrentCart(params: {
    body: CartPatchRequestBodyDto
  }): Observable<CartResponseDto> {

    return this.patchCurrentCart$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation validateCartAndGetPaymentLink
   */
  static readonly ValidateCartAndGetPaymentLinkPath = '/api/purchase/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateCartAndGetPaymentLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateCartAndGetPaymentLink$Response(params: {
    body: OrderCartDto
  }): Observable<StrictHttpResponse<PaymentLinkResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.ValidateCartAndGetPaymentLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentLinkResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateCartAndGetPaymentLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateCartAndGetPaymentLink(params: {
    body: OrderCartDto
  }): Observable<PaymentLinkResponseDto> {

    return this.validateCartAndGetPaymentLink$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentLinkResponseDto>) => r.body as PaymentLinkResponseDto)
    );
  }

  /**
   * Path part for operation verifyPayment
   */
  static readonly VerifyPaymentPath = '/api/purchase/order/verify-payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyPayment$Response(params?: {
  }): Observable<StrictHttpResponse<VerifyPaymentResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.VerifyPaymentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VerifyPaymentResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifyPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyPayment(params?: {
  }): Observable<VerifyPaymentResponseDto> {

    return this.verifyPayment$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyPaymentResponseDto>) => r.body as VerifyPaymentResponseDto)
    );
  }

  /**
   * Path part for operation placeOrderAndCleanUp
   */
  static readonly PlaceOrderAndCleanUpPath = '/api/purchase/order/place';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeOrderAndCleanUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeOrderAndCleanUp$Response(params?: {
  }): Observable<StrictHttpResponse<OrderPlacedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.PlaceOrderAndCleanUpPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderPlacedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeOrderAndCleanUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeOrderAndCleanUp(params?: {
  }): Observable<OrderPlacedResponseDto> {

    return this.placeOrderAndCleanUp$Response(params).pipe(
      map((r: StrictHttpResponse<OrderPlacedResponseDto>) => r.body as OrderPlacedResponseDto)
    );
  }

  /**
   * Path part for operation getAllPlacedOrders
   */
  static readonly GetAllPlacedOrdersPath = '/api/purchase/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlacedOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlacedOrders$Response(params?: {
  }): Observable<StrictHttpResponse<AdminPanelOrdersResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.GetAllPlacedOrdersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminPanelOrdersResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPlacedOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlacedOrders(params?: {
  }): Observable<AdminPanelOrdersResponseDto> {

    return this.getAllPlacedOrders$Response(params).pipe(
      map((r: StrictHttpResponse<AdminPanelOrdersResponseDto>) => r.body as AdminPanelOrdersResponseDto)
    );
  }

}
