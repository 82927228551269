import { AdminPanelProductListDto, ArticleDto, ArticleStatus, Currency } from '../../api';

export const mockArticle: ArticleDto = {
  id: '1',
  name: 'Article 1',
  distributorArticleId: '1',
  netPrice: {
    value: 20,
    currency: Currency.Inr,
  },
  grossPrice: {
    value: 25,
    currency: Currency.Inr,
  },
  grossPricePerUnit: {
    value: 25 / 10,
    currency: Currency.Inr,
  },
  packagingSize: {
    value: 10,
    unitOfMeasurement: 'kg',
  },
  stock: 1,
  vat: 1,
  status: ArticleStatus.Active,
};

export const mockAdminPanelProductList: AdminPanelProductListDto = {
  products: [
    {
      id: '111',
      name: 'Product 1',
      articles: [
        structuredClone(mockArticle),
        {
          id: '2',
          name: 'Article 2',
          distributorArticleId: '2',
          netPrice: {
            value: 30,
            currency: Currency.Inr,
          },
          grossPrice: {
            value: 35,
            currency: Currency.Inr,
          },
          grossPricePerUnit: {
            value: 35 / 5,
            currency: Currency.Inr,
          },
          packagingSize: {
            value: 5,
            unitOfMeasurement: 'lbs',
          },
          stock: 2,
          vat: 2,
          status: ArticleStatus.Active,
        },
        {
          id: '3',
          name: 'Article 3',
          distributorArticleId: '3',
          netPrice: {
            value: 15,
            currency: Currency.Inr,
          },
          grossPrice: {
            value: 18,
            currency: Currency.Inr,
          },
          grossPricePerUnit: {
            value: 18 / 8,
            currency: Currency.Inr,
          },
          packagingSize: {
            value: 8,
            unitOfMeasurement: 'kg',
          },
          stock: 3,
          vat: 3,
          status: ArticleStatus.Active,
        },
      ],
      sbu: null,
      productGroup: null,
      alphaCode: null,
    },
    {
      id: '222',
      name: 'Product 2',
      articles: [
        {
          id: '4',
          name: 'Article 4',
          distributorArticleId: '4',
          netPrice: {
            value: 25,
            currency: Currency.Inr,
          },
          grossPrice: {
            value: 30,
            currency: Currency.Inr,
          },
          grossPricePerUnit: { currency: Currency.Inr, value: 30 / 12 },
          packagingSize: {
            value: 12,
            unitOfMeasurement: 'lbs',
          },
          stock: 4,
          vat: 4,
          status: ArticleStatus.Active,
        },
        {
          id: '5',
          name: 'Article 5',
          distributorArticleId: '5',
          netPrice: {
            value: 18,
            currency: Currency.Inr,
          },
          grossPrice: {
            value: 20,
            currency: Currency.Inr,
          },
          grossPricePerUnit: { currency: Currency.Inr, value: 20 / 6 },
          packagingSize: {
            value: 6,
            unitOfMeasurement: 'kg',
          },
          stock: 5,
          vat: 5,
          status: ArticleStatus.Active,
        },
      ],
      sbu: null,
      productGroup: null,
      alphaCode: null,
    },
    {
      id: '333',
      name: 'Product 3',
      articles: [],
      sbu: null,
      productGroup: null,
      alphaCode: null,
    },
  ],
};
