/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPanelUsersResponseDto } from '../models/admin-panel-users-response-dto';
import { PatchUserDto } from '../models/patch-user-dto';
import { UserBasicInfoResponseDto } from '../models/user-basic-info-response-dto';

@Injectable({
  providedIn: 'root',
})
export class UserInformationApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBasicUserInfo
   */
  static readonly GetBasicUserInfoPath = '/api/user/basic-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBasicUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBasicUserInfo$Response(params?: {
  }): Observable<StrictHttpResponse<UserBasicInfoResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserInformationApiService.GetBasicUserInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserBasicInfoResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBasicUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBasicUserInfo(params?: {
  }): Observable<UserBasicInfoResponseDto> {

    return this.getBasicUserInfo$Response(params).pipe(
      map((r: StrictHttpResponse<UserBasicInfoResponseDto>) => r.body as UserBasicInfoResponseDto)
    );
  }

  /**
   * Path part for operation patchUser
   */
  static readonly PatchUserPath = '/api/user/basic-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUser$Response(params: {
    body: PatchUserDto
  }): Observable<StrictHttpResponse<UserBasicInfoResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserInformationApiService.PatchUserPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserBasicInfoResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUser(params: {
    body: PatchUserDto
  }): Observable<UserBasicInfoResponseDto> {

    return this.patchUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserBasicInfoResponseDto>) => r.body as UserBasicInfoResponseDto)
    );
  }

  /**
   * Path part for operation getAllAdminPanelUsers
   */
  static readonly GetAllAdminPanelUsersPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAdminPanelUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAdminPanelUsers$Response(params?: {
  }): Observable<StrictHttpResponse<AdminPanelUsersResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserInformationApiService.GetAllAdminPanelUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminPanelUsersResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllAdminPanelUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAdminPanelUsers(params?: {
  }): Observable<AdminPanelUsersResponseDto> {

    return this.getAllAdminPanelUsers$Response(params).pipe(
      map((r: StrictHttpResponse<AdminPanelUsersResponseDto>) => r.body as AdminPanelUsersResponseDto)
    );
  }

}
