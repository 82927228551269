/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  AdminPanelUsersResponseDto,
  PatchUserDto,
  UserBasicInfoResponseDto,
  UserInformationApiService,
} from '../../api';
import { mockAdminPanelUsersResponse, mockUserInfo } from '../data';

const localDB = {};

const serverDelay = 500; // ms

export const mockUserInfoApiService: Partial<UserInformationApiService> = {
  getBasicUserInfo(): Observable<UserBasicInfoResponseDto> {
    console.log('[UserInformationApiService::getBasicUserInfo]');
    return of({ user: mockUserInfo }).pipe(delay(serverDelay));
  },
  patchUser(params: { body: PatchUserDto }): Observable<UserBasicInfoResponseDto> {
    // Just for testing
    localStorage.setItem('productSearchQueryString', params.body.productSearchQueryString ?? '');

    return of({ user: mockUserInfo }).pipe(delay(serverDelay));
  },
  getAllAdminPanelUsers(params?: any): Observable<AdminPanelUsersResponseDto> {
    return of(mockAdminPanelUsersResponse).pipe(delay(serverDelay));
  },
};
