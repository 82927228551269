import { AdminPanelOrderDto, AdminPanelOrdersResponseDto } from '../../api';

export const mockPlacedOrder: AdminPanelOrderDto = {
  billingAddressAdditionalInfo: 'Mock Billing Address Additional Info',
  billingAddressCity: 'Mock Billing Address City',
  billingAddressStreet: 'Mock Billing Address Street',
  billingAddressZipCode: 'Mock Billing Address Zip Code',
  createdAt: '2023-06-01T00:00:00Z',
  deliveryInstructions: null,
  gstNumber: 'Mock GST Number',
  id: 'MockId',
  orderCurrency: 'USD',
  orderPlacedAt: '2023-06-01T00:00:00Z',
  orderStatus: 'Mock Order Status',
  panNumber: 'Mock PAN Number',
  poNumber: 'Mock PO Number',
  recipientEmail: 'mock@example.com',
  recipientName: 'Mock Recipient Name',
  recipientPhone: null,
  shippingAddressAdditionalInfo: 'Mock Shipping Address Additional Info',
  shippingAddressCity: 'Mock Shipping Address City',
  shippingAddressStreet: 'Mock Shipping Address Street',
  shippingAddressZipCode: 'Mock Shipping Address Zip Code',
  termsAndConditionsAcceptedAt: '2023-06-01T00:00:00Z',
  termsAndConditionsUrl: 'https://example.com/terms',
  totalGrossPrice: 100.0,
  totalNetPrice: 80.0,
  updatedAt: '2023-06-01T00:00:00Z',
  userId: '1',
  orderLineItems: [
    {
      articleName: 'Mock Article Name',
      articleGrossPrice: 10.0,
      articleId: '1',
      articleNetPrice: 8.0,
      articleQuantity: 2,
      grossPrice: 20.0,
      id: '1',
      netPrice: 16.0,
      orderId: '1',
      packagingSizeUnit: 'pcs',
      packagingSizeValue: 1,
      priceCurrency: 'USD',
      productId: '1',
      productName: 'Mock Product Name 1',
      requestedDeliveryDate: '2023-06-01T00:00:00Z',
    },
    {
      articleName: 'Mock Article Name 2',
      articleGrossPrice: 10.0,
      articleId: '2',
      articleNetPrice: 8.0,
      articleQuantity: 2,
      grossPrice: 20.0,
      id: '2',
      netPrice: 16.0,
      orderId: '2',
      packagingSizeUnit: 'pcs',
      packagingSizeValue: 1,
      priceCurrency: 'USD',
      productId: '2',
      productName: 'Mock Product Name 2',
      requestedDeliveryDate: '2023-06-01T00:00:00Z',
    },
    {
      articleName: 'Mock Article Name 3',
      articleGrossPrice: 10.0,
      articleId: '3',
      articleNetPrice: 8.0,
      articleQuantity: 2,
      grossPrice: 20.0,
      id: '3',
      netPrice: 16.0,
      orderId: '3',
      packagingSizeUnit: 'pcs',
      packagingSizeValue: 1,
      priceCurrency: 'USD',
      productId: '3',
      productName: 'Mock Product Name 3',
      requestedDeliveryDate: '2023-06-01T00:00:00Z',
    },
  ],
};

export const mockPlacedOrderWithOneLineItem: AdminPanelOrderDto = {
  billingAddressAdditionalInfo: 'Mock Billing Address Additional Info',
  billingAddressCity: 'Mock Billing Address City',
  billingAddressStreet: 'Mock Billing Address Street',
  billingAddressZipCode: 'Mock Billing Address Zip Code',
  createdAt: '2023-02-01T00:00:00Z',
  deliveryInstructions: null,
  gstNumber: 'Mock GST Number',
  id: 'MockId2',
  orderCurrency: 'USD',
  orderPlacedAt: '2023-02-01T00:00:00Z',
  orderStatus: 'Sample Order Status',
  panNumber: 'Mock PAN Number',
  poNumber: 'Sample Purchase Order Number',
  recipientEmail: 'mock@example.com',
  recipientName: 'Mock Recipient Name',
  recipientPhone: null,
  shippingAddressAdditionalInfo: 'Mock Shipping Address Additional Info',
  shippingAddressCity: 'Mock Shipping Address City',
  shippingAddressStreet: 'Mock Shipping Address Street',
  shippingAddressZipCode: 'Mock Shipping Address Zip Code',
  termsAndConditionsAcceptedAt: '2023-06-01T00:00:00Z',
  termsAndConditionsUrl: 'https://example.com/terms',
  totalGrossPrice: 40.0,
  totalNetPrice: 20.0,
  updatedAt: '2023-06-01T00:00:00Z',
  userId: '1',
  orderLineItems: [
    {
      articleName: 'Mock Article Name',
      articleGrossPrice: 10.0,
      articleId: '1',
      articleNetPrice: 8.0,
      articleQuantity: 2,
      grossPrice: 20.0,
      id: '1',
      netPrice: 16.0,
      orderId: '1',
      packagingSizeUnit: 'pcs',
      packagingSizeValue: 1,
      priceCurrency: 'USD',
      productId: '1',
      productName: 'Mock Product Name 1',
      requestedDeliveryDate: '2023-06-01T00:00:00Z',
    },
  ],
};

export const mockPlacedOrdersResponse: AdminPanelOrdersResponseDto = {
  placedOrders: [
    mockPlacedOrderWithOneLineItem,
    ...Array(23).fill(mockPlacedOrder),
    mockPlacedOrderWithOneLineItem,
  ],
  users: [
    {
      email: 'mock@example.com',
      firstName: 'Mock First Name',
      id: '1',
      lastLoginAt: null,
      lastName: 'Mock Last Name',
    },
  ],
};
