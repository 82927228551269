/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminPanelProductListDto } from '../models/admin-panel-product-list-dto';
import { ArticleDto } from '../models/article-dto';
import { CreateNewArticleDto } from '../models/create-new-article-dto';
import { NavigatorFilterTreeDto } from '../models/navigator-filter-tree-dto';
import { PatchArticleDto } from '../models/patch-article-dto';
import { ProductDetailsDto } from '../models/product-details-dto';
import { ProductListDto } from '../models/product-list-dto';
import { ProductListRequestBodyDto } from '../models/product-list-request-body-dto';
import { SimpleProductsResponseDto } from '../models/simple-products-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProductList
   */
  static readonly GetProductListPath = '/api/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProductList$Response(params: {
    body: ProductListRequestBodyDto
  }): Observable<StrictHttpResponse<ProductListDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetProductListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProductList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProductList(params: {
    body: ProductListRequestBodyDto
  }): Observable<ProductListDto> {

    return this.getProductList$Response(params).pipe(
      map((r: StrictHttpResponse<ProductListDto>) => r.body as ProductListDto)
    );
  }

  /**
   * Path part for operation getIndustriesFilterOptions
   */
  static readonly GetIndustriesFilterOptionsPath = '/api/products/navigator-filter-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndustriesFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndustriesFilterOptions$Response(params?: {
  }): Observable<StrictHttpResponse<NavigatorFilterTreeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetIndustriesFilterOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NavigatorFilterTreeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIndustriesFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndustriesFilterOptions(params?: {
  }): Observable<NavigatorFilterTreeDto> {

    return this.getIndustriesFilterOptions$Response(params).pipe(
      map((r: StrictHttpResponse<NavigatorFilterTreeDto>) => r.body as NavigatorFilterTreeDto)
    );
  }

  /**
   * Path part for operation getSimpleProducts
   */
  static readonly GetSimpleProductsPath = '/api/products/simple-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProducts$Response(params?: {
  }): Observable<StrictHttpResponse<SimpleProductsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetSimpleProductsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleProductsResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimpleProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProducts(params?: {
  }): Observable<SimpleProductsResponseDto> {

    return this.getSimpleProducts$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleProductsResponseDto>) => r.body as SimpleProductsResponseDto)
    );
  }

  /**
   * Path part for operation getAdminPanelProductList
   */
  static readonly GetAdminPanelProductListPath = '/api/products/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdminPanelProductList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminPanelProductList$Response(params?: {
  }): Observable<StrictHttpResponse<AdminPanelProductListDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetAdminPanelProductListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminPanelProductListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdminPanelProductList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminPanelProductList(params?: {
  }): Observable<AdminPanelProductListDto> {

    return this.getAdminPanelProductList$Response(params).pipe(
      map((r: StrictHttpResponse<AdminPanelProductListDto>) => r.body as AdminPanelProductListDto)
    );
  }

  /**
   * Path part for operation createArticle
   */
  static readonly CreateArticlePath = '/api/products/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArticle$Response(params: {
    body: CreateNewArticleDto
  }): Observable<StrictHttpResponse<ArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.CreateArticlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArticle(params: {
    body: CreateNewArticleDto
  }): Observable<ArticleDto> {

    return this.createArticle$Response(params).pipe(
      map((r: StrictHttpResponse<ArticleDto>) => r.body as ArticleDto)
    );
  }

  /**
   * Path part for operation updateArticle
   */
  static readonly UpdateArticlePath = '/api/products/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArticle$Response(params: {
    id: string;
    body: PatchArticleDto
  }): Observable<StrictHttpResponse<ArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.UpdateArticlePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArticle(params: {
    id: string;
    body: PatchArticleDto
  }): Observable<ArticleDto> {

    return this.updateArticle$Response(params).pipe(
      map((r: StrictHttpResponse<ArticleDto>) => r.body as ArticleDto)
    );
  }

  /**
   * Path part for operation getProductDetails
   */
  static readonly GetProductDetailsPath = '/api/products/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetails$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ProductDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetProductDetailsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProductDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetails(params: {
    id: string;
  }): Observable<ProductDetailsDto> {

    return this.getProductDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDetailsDto>) => r.body as ProductDetailsDto)
    );
  }

}
