import { sub } from 'date-fns';

import { AdminPanelUsersResponseDto, UserBasicInfoDto } from '../../api';

export const mockUserInfo: UserBasicInfoDto = {
  email: 'johndoe@mailinator.com',
  firstName: 'John',
  lastName: 'Doe',
  productSearchQueryString: '',
  roles: ['CUG_WCM_EVP_DISTRIBUTOR_ADMIN'],
};

export const mockAdminPanelUsers = [
  {
    email: 'test@mailinator.com',
    firstName: 'Test',
    lastName: 'Joe',
    id: '1',
    lastLoginAt: null,
  },
  {
    email: 'michael@mailinator.com',
    firstName: 'Michael',
    lastName: 'Jordan',
    id: '2',
    lastLoginAt: new Date().toISOString(),
  },
  {
    email: 'woslowsky@mailinator.com',
    firstName: 'Woslowsky',
    lastName: 'Watson',
    id: '3',
    lastLoginAt: sub(new Date(), { days: 2 }).toISOString(),
  },
];

export const mockAdminPanelUsersResponse: AdminPanelUsersResponseDto = {
  users: mockAdminPanelUsers,
};
