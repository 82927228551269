/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  AdminPanelProductListDto,
  ArticleDto,
  CreateNewArticleDto,
  NavigatorFilterTreeDto,
  PatchArticleDto,
  ProductDetailsDto,
  ProductListDto,
  ProductListRequestBodyDto,
  ProductsApiService,
  SimpleProductsResponseDto,
} from '../../api';
import {
  mockAdminPanelProductList,
  mockArticle,
  mockCategoryOptions,
  mockProductDetails,
  mockProductList,
} from '../data';

const localDB = {
  productDetails: mockProductDetails,
  categoryOptions: mockCategoryOptions,
};

const serverDelay = 500; // ms

export const mockProductsApiService: Partial<ProductsApiService> = {
  getProductDetails(params: { id: string }): Observable<ProductDetailsDto> {
    return of(localDB.productDetails).pipe(delay(serverDelay));
  },

  getIndustriesFilterOptions: function (params?: {
    locale?: string;
  }): Observable<NavigatorFilterTreeDto> {
    return of({ industryNavigatorFilterTree: localDB.categoryOptions }).pipe(delay(serverDelay));
  },

  getProductList: function (params?: {
    body: ProductListRequestBodyDto;
  }): Observable<ProductListDto> {
    // return throwError(() => new Error('An error occurred after 1 second!'));
    return of(mockProductList).pipe(delay(serverDelay));
  },

  getSimpleProducts: function (params?: {
    locale?: string;
  }): Observable<SimpleProductsResponseDto> {
    return of({ products: mockProductList.products }).pipe(delay(serverDelay));
  },

  getAdminPanelProductList(params?: any): Observable<AdminPanelProductListDto> {
    return of(mockAdminPanelProductList).pipe(delay(serverDelay));
  },

  createArticle(params: { body: CreateNewArticleDto }): Observable<ArticleDto> {
    return of(mockArticle).pipe(delay(serverDelay));
  },

  updateArticle(params: { id: string; body: PatchArticleDto }): Observable<ArticleDto> {
    return of(mockArticle).pipe(delay(serverDelay));
  },
};
