import {
  ArticleStatus,
  Currency,
  ProductDetailsDto,
  ProductSustainabilityAttributeDto,
  ProductSustainabilityCategoryEnum,
} from '../../api';

export const mockProductDetails: ProductDetailsDto = getPrimary();
export const mockProductDetails2: ProductDetailsDto = getSecondary();

function getPrimary(): ProductDetailsDto {
  const primary: ProductDetailsDto = {
    id: '30502414',
    name: 'Magnafloc® 1425',
    slug: 'magnafloc-1425',
    description: `Magnafloc is a unique, premium product, which was invented by Samuel L. Jackson back in the 18th century, and since 1926 it's produced by BASF Europe GmbH in Ludwigshafen, Germany.`,
    alphaCode: null,
    productGroup: null,
    sbu: null,
    productAttributes: [],
    sustainabilityAttributes: getAllSustainabilityAttributes(),
    articles: [
      {
        id: '123123',
        distributorArticleId: '123123',
        name: 'Magnafloc® 1425 ',
        netPrice: { currency: Currency.Inr, value: 9.99 },
        grossPrice: { currency: Currency.Inr, value: 9.99 },
        grossPricePerUnit: { currency: Currency.Inr, value: 9.99 / 5 },
        packagingSize: {
          value: 100,
          unitOfMeasurement: 'KGM',
        },
        stock: 100,
        vat: 12.5,
        status: ArticleStatus.Active,
      },
      {
        id: '123123',
        distributorArticleId: '124124',
        name: 'Magnafloc® 1425 1080KG Composite - IBC (PE/Metal)',
        netPrice: { currency: Currency.Inr, value: 9.99 },
        grossPrice: { currency: Currency.Inr, value: 9.99 },
        grossPricePerUnit: { currency: Currency.Inr, value: 9.99 / 5 },
        packagingSize: {
          value: 1080,
          unitOfMeasurement: 'KGM',
        },
        stock: 0,
        vat: 12.5,
        status: ArticleStatus.Active,
      },
    ],
  };

  return primary;
}

function getSecondary(): ProductDetailsDto {
  const secondary: ProductDetailsDto = {
    id: '123',
    name: 'Irganox1010',
    slug: 'irganox-1010',
    description: 'this is dummy description',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVK',
    articles: [
      {
        id: '123123',
        distributorArticleId: '123123',
        name: 'Irganox 1010 5KG ***Test',
        netPrice: { currency: Currency.Inr, value: 9.99 },
        grossPrice: { currency: Currency.Inr, value: 9.99 },
        grossPricePerUnit: { currency: Currency.Inr, value: 9.99 / 5 },
        packagingSize: {
          value: 5,
          unitOfMeasurement: 'KGM',
        },
        stock: 5,
        vat: 12.5,
        status: ArticleStatus.Active,
      },
      {
        id: '123123',
        distributorArticleId: '123123',
        name: 'Irganox 1010 5KG ***Test',
        netPrice: { currency: Currency.Inr, value: 9.99 },
        grossPrice: { currency: Currency.Inr, value: 9.99 },
        grossPricePerUnit: { currency: Currency.Inr, value: 9.99 / 5 },
        packagingSize: {
          value: 5,
          unitOfMeasurement: 'KGM',
        },
        stock: 0,
        vat: 12.5,
        status: ArticleStatus.Active,
      },
      {
        id: '123123',
        distributorArticleId: '123123',
        name: 'Irganox 1010 220KG ***Test',
        netPrice: { currency: Currency.Inr, value: 9.99 },
        grossPrice: { currency: Currency.Inr, value: 9.99 },
        grossPricePerUnit: { currency: Currency.Inr, value: 9.99 / 5 },
        packagingSize: {
          value: 1080,
          unitOfMeasurement: 'KGM',
        },
        stock: 0,
        vat: 12.5,
        status: ArticleStatus.Active,
      },
    ],
    productAttributes: [],
    sustainabilityAttributes: getAllSustainabilityAttributes(),
  };

  return secondary;
}

function getAllSustainabilityAttributes(): ProductSustainabilityAttributeDto[] {
  return [
    {
      name: 'Increased quality of product	',
      id: '8a8081b98902bf3501891f3b229136a5',
      category: ProductSustainabilityCategoryEnum.CostSavings,
    },
    {
      name: 'Enables conservation of biodiversity (e.g. less deforestation or overfishing)	',
      id: '8a8081b98902bf3501891f3d886036ae',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Reduced environmental exposure due to optimized application technology (e.g. bait technology)	',
      id: '8a8081b98902bf3501891f540ee23796',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'More favorable behavior (e.g. less leaching)',
      id: '8a8081b98902bf3501891f54ca0e379e',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Higher compatibility with low-drift technologies',
      id: '8a8081b98902bf3501891f53dc593794',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'With improved ecotox profile',
      id: '8a8081b98902bf3501891f549f5f379c',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Positive effect on diversity of ecosystems (e.g. soil organisms)',
      id: '8a8081b98902bf3501891f53ac563792',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Integrated resistance management: unique position with new mode of action (MOA), curative control of sudden disease outbreaks, disruptive/shifting type/multisite MOA',
      id: '8a8081b98902bf3501891f5472a3379a',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Solution enables cost savings for growers, plant raisers, processors, and retailers',
      id: '8a8081b98902bf3501891f3c3a2c36aa',
      category: ProductSustainabilityCategoryEnum.CostSavings,
    },
    {
      name: 'Reduced land use',
      id: '8a8081b98902bf3501891f543e213798',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Solution enables cost savings by the farmer and/or along the value chain',
      id: '8a8081b98902bf3501891f3c083836a8',
      category: ProductSustainabilityCategoryEnum.CostSavings,
    },
    {
      name: 'Reduced risk for non-target organisms or beneficial (e.g. Increased specificity)',
      id: '8a8081b98902bf3501891f3dc16536b0',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Enables cost savings downstream and/or along the value chain, e.g., through less energy intensive processes.',
      id: '8a80819186175a4f018617dc5e695fb3',
      category: ProductSustainabilityCategoryEnum.CostSavings,
    },
  ];
}
